import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import { Constant } from "../../constant";
import { Subscription } from 'rxjs';
import { EventManagerService } from "../../service/event-manager.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from "../../service/dialog.service";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'view-image',
  templateUrl: "./view-image.html",
  styleUrls: ['./view-image.scss'],
})

export class ViewImageComponent implements OnInit, OnChanges, OnDestroy  {
    @Input('image') image: any = {};
    @Input('value') value: any = {};
    photo: any = {};
    event: Subscription;
    Constant = Constant;
    rotatePhoto = 1;
    widthRotate: string = '';
    heightRotate: string = '';
    imageElement: any;
    isVerticalImage: boolean = false;
    isChangeCompareOrigin: boolean = false;
    id: number = Date.now();
    aspectRatio: number = 1;
    levelZoom: number = 1;
    constructor(
        public activeModal: NgbActiveModal,
        public dialog: DialogService,
        private eventManagerService: EventManagerService
    ) {

    }
    ngOnInit() {
        const backdrop = document.getElementsByTagName('ngb-modal-backdrop')[0];
        if (backdrop) {
            backdrop.classList.add("large-z-index-backdrop");
        }
        this.checkImage();
    }
    ngOnChanges(changes: SimpleChanges) {
        this.checkImage();
    }
    ngOnDestroy() {
        const backdrop = document.getElementsByTagName('ngb-modal-backdrop')[0];
        if (backdrop) {
            backdrop.classList.remove("large-z-index-backdrop");
        }
    }
    checkImage() {
        this.photo = this.image || this.value || this.photo;
        if (!this.photo) {
            this.dismiss();
            this.dialog.showError('Ảnh bị lỗi không thể xem được.');
            return;
        } else {
            this.checkExistUrlImage(this.photo.url, 0);
        }
    }
    checkExistUrlImage(url, time) {
        var image = new Image();
        image.src = url;
        if (image.width == 0 && time < 4) {
            setTimeout(() => {
                time++;
                this.checkExistUrlImage(url, time);
            }, 500)
        } else if (image.width > 0) {
            this.imageElement = image;
            this.isVerticalImage =  this.imageElement.height > this.imageElement.width;
            this.aspectRatio  = this.imageElement.width / this.imageElement.height;
            this.changeWidthHeight();
           return;
        } 
        if (time === 4) {
            this.dialog.showError('Ảnh bị lỗi không thể xem được.');
        }
    }
    dismiss() {
        this.activeModal.dismiss();
        document.getElementsByTagName('ngb-modal-backdrop')[0].classList.remove("large-z-index-backdrop");
    }
    getRotate() {
        let cla = '';
        switch (this.rotatePhoto) {
            case 1:
                cla = 'rotate0';
            break;
            case 2:
                cla = 'rotate90';
            break;
            case 3:
                cla = 'rotate180';
            break;
            case 4:
                cla = 'rotate270';
            break;
        }
        return cla;
    }
    getWidthHeightFollowRotate() {
        const image: any = document.getElementById('photo-' + this.id);
        if (!image) {
            return;
        }
        let isChangeCompareOrigin = false;
        switch (this.rotatePhoto) {
            case 1:
                isChangeCompareOrigin = false;
            break;
            case 2:
                isChangeCompareOrigin = true;
            break;
            case 3:
                isChangeCompareOrigin = false;
            break;
            case 4:
                isChangeCompareOrigin = true;
            break;
        }
        this.isChangeCompareOrigin= isChangeCompareOrigin;
        this.changeWidthHeight();
    }
    rotatePrevious() {
        if (this.rotatePhoto > 1) {
            this.rotatePhoto--;
        }
        this.getWidthHeightFollowRotate();
    }
    rotateNext() {
        if (this.rotatePhoto < 4) {
            this.rotatePhoto++;
        }
        this.getWidthHeightFollowRotate();
    }
    zoomIn() {
        this.levelZoom++;
        this.changeWidthHeight();
    }
    zoomOut() {
        this.levelZoom--;
        this.changeWidthHeight();
    }
    changeWidthHeight() {
        const width = (this.imageElement.width * (this.levelZoom* 25 + 100)/ 100) 
        const height = (this.imageElement.height * (this.levelZoom* 25 + 100)/ 100);
        let widthRotate, heightRotate;
        if (this.isChangeCompareOrigin) {
            widthRotate = height;;
            heightRotate = width;
        } else {
            widthRotate = width;
            heightRotate = height;
        }
        if (widthRotate / heightRotate < 1) {
            this.aspectRatio = widthRotate / heightRotate
            this.widthRotate = widthRotate  + 'px';
            this.heightRotate = heightRotate  + 'px';
        } else {
            this.aspectRatio = 1;
            this.widthRotate = widthRotate  + 'px';
            this.heightRotate = widthRotate  + 'px';
        }
    }
    download() {
        window.open(this.photo.url, '_blank');
    }
}
