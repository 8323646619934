import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from 'src/app/common/constant';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Dispatcher, StoreInfoUser } from 'src/app/state';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { UserProfile } from '../models/users.model';
declare let $: any;
@Injectable({ providedIn: 'root' })
export class  AccountService  {
    constructor(
        private http: HttpClient,
        private router: Router,
        private dispatcher: Dispatcher,
        private localStorage: LocalStorageService, 
        private sessionStorage: SessionStorageService) {
    }
    logout() {
        this.clearLocal();
        this.goToLogin();
    }
    goToLogin() {
        $(document.body).removeClass("modal-open");
        $(document.body).css('padding-right','0');
        
        localStorage.setItem(Constant.key_local_login_sucess, "false");
        //Go to screen login
        this.router.navigate([Constant.url_login]);
        
    };
    clearLocal() {
        //Remove token when logout success
        this.dispatcher.fire(new StoreInfoUser(new UserProfile()));
        sessionStorage.removeItem(Constant.key_local_token);
        localStorage.removeItem(Constant.key_local_token);
        this.sessionStorage.store('TOKEN', '');
        this.localStorage.store('TOKEN', '');
    }
    changePassword(body) {
        return this.http.post(environment.urlBackEnd + 'agency-partner/agency-change-password-partner',body);
    }
}
